var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${
    _vm.sidebarHeightApplied
      ? 'data-source-container-sidebar'
      : 'data-source-container'
  }`},[(_vm.label.length)?_c('div',{staticClass:"top-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isMandatory)?_c('span',{staticClass:"isMandatory"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('dropdown-wrap-vue',{on:{"onClickOutside":_vm.onClickOutside}},[_c('div',{staticClass:"dropdown-wrap"},[_c('b-button',{class:[
          'data-source-header',
          _vm.label.length && _vm.open ? 'active' : '',
          !_vm.label.length ? 'local-button-background' : ''
        ],on:{"click":function($event){return _vm.openTab()}}},[_c('div',{staticClass:"button-container"},[_c('div',{class:[
              'ellipsis',
              !_vm.label.length ? 'active-color' : '',
              _vm.applyPlaceholderColor ? 'placeholder-color' : ''
            ]},[_vm._v(" "+_vm._s(_vm.label.length ? _vm.localSelected.length ? _vm.localSelected.length == 1 ? _vm.localSelected[0].name : _vm.isSelected ? 'All' : 'Multiple' : _vm.placeholder : _vm.placeholder)+" ")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"7.072","height":"7.072","viewBox":"0 0 7.072 7.072","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"fill":"#a8aab7","d":"M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open && _vm.list.length),expression:"open && list.length"}],class:[
          'data-source-abs',
          _vm.className,
          !_vm.label.length ? 'local-button' : ''
        ]},[_c('ul',[_c('li',[_c('label',{class:[
                'custom-checkbox',
                !_vm.label.length ? 'custom-checkbox-unique' : ''
              ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isSelected),expression:"isSelected"}],attrs:{"type":"checkbox","id":"selectAll"},domProps:{"checked":Array.isArray(_vm.isSelected)?_vm._i(_vm.isSelected,null)>-1:(_vm.isSelected)},on:{"click":function($event){return _vm.selectAllData(!_vm.isSelected)},"change":function($event){var $$a=_vm.isSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isSelected=$$c}}}}),_c('div',{staticClass:"checkmark"}),_c('p',[_vm._v(_vm._s('Select All'))])])])]),_vm._l((_vm.list),function(object,index){return _c('ul',{key:index},[_c('li',[_c('label',{class:[
                'custom-checkbox',
                object.parent_id ? 'extra-padding-left' : '',
                !_vm.label.length ? 'custom-checkbox-unique' : ''
              ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localSelected),expression:"localSelected"}],attrs:{"type":"checkbox","id":object},domProps:{"value":object,"checked":Array.isArray(_vm.localSelected)?_vm._i(_vm.localSelected,object)>-1:(_vm.localSelected)},on:{"change":function($event){var $$a=_vm.localSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=object,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localSelected=$$c}}}}),_c('div',{staticClass:"checkmark"}),_c('div',{staticClass:"line-block"},[_c('p',[_vm._v(_vm._s(object.name))]),(_vm.custom)?_c('div',{staticClass:"align-text"},[_c('img',{attrs:{"src":require(`../../assets/${object.name}.svg`),"alt":object.name}}),(_vm.statistics[object.name])?_c('p',[_vm._v(" "+_vm._s(!isNaN(_vm.statistics[object.name]) ? Number(_vm.statistics[object.name].toFixed(1)) + '%' : '')+" ")]):_c('p',[_vm._v("0%")])]):_vm._e()])])])])})],2)],1),(_vm.isMandatory && _vm.submitted && _vm.localSelected.length < 1)?_c('div',{staticClass:"required-error"},[_vm._v(" "+_vm._s(_vm.label)+" is required ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }