<template>
  <!-- <div class="data-source-container"> -->
  <div
    :class="`${
      sidebarHeightApplied
        ? 'data-source-container-sidebar'
        : 'data-source-container'
    }`">
    <div v-if="label.length" class="top-label">
      {{ label }} <span v-if="isMandatory" class="isMandatory">*</span>
    </div>
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <div class="dropdown-wrap">
        <b-button
          :class="[
            'data-source-header',
            label.length && open ? 'active' : '',
            !label.length ? 'local-button-background' : ''
          ]"
          @click="openTab()">
          <div class="button-container">
            <div
              :class="[
                'ellipsis',
                !label.length ? 'active-color' : '',
                applyPlaceholderColor ? 'placeholder-color' : ''
              ]">
              {{
                label.length
                  ? localSelected.length
                    ? localSelected.length == 1
                      ? localSelected[0].name
                      : isSelected
                      ? 'All'
                      : 'Multiple'
                    : placeholder
                  : placeholder
              }}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          </div>
        </b-button>
        <div
          :class="[
            'data-source-abs',
            className,
            !label.length ? 'local-button' : ''
          ]"
          v-show="open && list.length">
          <ul>
            <li>
              <label
                :class="[
                  'custom-checkbox',
                  !label.length ? 'custom-checkbox-unique' : ''
                ]">
                <input
                  type="checkbox"
                  id="selectAll"
                  v-model="isSelected"
                  @click="selectAllData(!isSelected)" />
                <div class="checkmark"></div>
                <p>{{ 'Select All' }}</p>
              </label>
            </li>
          </ul>
          <ul v-for="(object, index) of list" :key="index">
            <li>
              <label
                :class="[
                  'custom-checkbox',
                  object.parent_id ? 'extra-padding-left' : '',
                  !label.length ? 'custom-checkbox-unique' : ''
                ]">
                <input
                  type="checkbox"
                  :id="object"
                  :value="object"
                  v-model="localSelected" />
                <div class="checkmark"></div>
                <div class="line-block">
                  <p>{{ object.name }}</p>
                  <div v-if="custom" class="align-text">
                    <img
                      :src="require(`../../assets/${object.name}.svg`)"
                      :alt="object.name" />
                    <p v-if="statistics[object.name]">
                      {{
                        !isNaN(statistics[object.name])
                          ? Number(statistics[object.name].toFixed(1)) + '%'
                          : ''
                      }}
                    </p>
                    <p v-else>0%</p>
                  </div>
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="isMandatory && submitted && localSelected.length < 1"
        class="required-error">
        {{ label }} is required
      </div>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { analyticsEvents } from '../../constant/data'
import DropdownWrapVue from '../Common/DropdownWrap.vue'

/**
 * This component acts a multi-selector dropdown. It does not have search/filtering capabilites. It is used in the sidepanel/global filters and on the **Comments** page as well.
 */
export default {
  name: 'MultiSelector',
  components: {
    DropdownWrapVue
  },
  // props: ['list', 'onChange', 'selected', 'label', 'placeholder', 'custom', 'statistics', 'className'],
  props: {
    /** This prop contains the list that is used to populate the dropdown */
    list: Array,
    /** This prop defines the behaviour when a new item is selected */
    onChange: Function,
    /** This prop contains an array containing the information of selected objects */
    selected: Array,
    /** This prop contains/defines the text that appears as a label over the dropdown */
    label: String,
    /** This prop contains the text when no item has been selected */
    placeholder: String,
    /** This prop indicates that there are some custom elements that are inserted beside each *(Those under the v-for loop)* option in the dropdown. Currently used to insert the Reaction symbols and percentages on the Comments page  */
    custom: Boolean,
    /** This prop contains additional (statistical) info about each option in the dropdown. Currently being used on the Comments page  */
    statistics: Object,
    /** This prop contains a class name for the dropdown */
    className: String,

    sidebarHeightApplied: Boolean,
    applyPlaceholderColor: Boolean,
    isMandatory: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      localSelected: [],
      isSelected: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    selected () {
      if (
        this.selected.length === this.list.length &&
        this.selected.length > 0
      ) {
        this.isSelected = true
      } else {
        this.isSelected = false
      }
      this.localSelected = this.selected
    },
    localSelected () {
      if (this.list.length) {
        this.$emit('onChange', this.localSelected)
      }
    }
  },
  mounted () {
    this.localSelected = this.selected
    if (
      this.selected &&
      this.selected.length > 0 &&
      this.list &&
      this.list.length > 0
    ) {
      this.list.length === this.selected.length
        ? (this.isSelected = true)
        : (this.isSelected = false)
    } else {
      this.isSelected = false
    }
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    /**
     * This method closes the dropdown if a click is detected anywhere outside the boundary of the dropdown menu
     * @public
     */
    onClickOutside (event) {
      this.open = false
    },
    /**
     * This method opens the dropdown menu, and calls an *updateAnalytics()* method if the *updateAnalytics* prop is present
     * @public
     */
    openTab () {
      this.open = !this.open
      if (this.open) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label:
            analyticsEvents[this.label.length ? this.label : this.placeholder],
          pageUrl: window.location.href
        })
      }
    },
    /**
     * This method selects all options in the dropdown
     * @public
     */
    selectAllData (isSelected) {
      if (!isSelected) {
        this.localSelected = []
      } else {
        this.localSelected = this.list
      }
    }
  }
}
</script>

<style lang="scss" src="./common.scss" scoped>
</style>
